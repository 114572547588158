<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <v-btn
            dark
            color="secondary"
            class="ma-2"
            outlined
            @click="$router.back()"
          >
            <v-icon>mdi-arrow-left</v-icon>
            Back
          </v-btn>
        </v-card-title>
        <v-card-title class="pl-6">Video</v-card-title>
        <v-form class="pa-5" ref="form" lazy-validation>
          <v-select
            outlined
            label="Language"
            :items="languages"
            item-text="label"
            item-value="value"
            prepend-icon="mdi-format-list-bulleted-square"
            v-model="video.language"
            :rules="[requiredRule]"
          ></v-select>
          <v-text-field
            label="Description"
            outlined
            :rules="[requiredRule]"
            prepend-icon="mdi-text"
            v-model="video.description"
            color="primary"
          ></v-text-field>
          <div class="d-flex mb-8 pb-2">
            <v-icon class="pr-2 html-icon">mdi-video</v-icon>
            <div class="w-full pl-1">
              <div class="label grey--text">Upload Video</div>
              <vue-dropzone
                class="mt-5"
                ref="myVueDropzone"
                id="videoDropzone"
                :options="dropzoneOptions"
                @vdropzone-complete="uploadComplete"
                @vdropzone-error="errorEvent"
              ></vue-dropzone>
            </div>
          </div>
          <div v-if="video.video" class="container">
            <div class="row justify-center">
              <div class="col-4">
                <video-player :input="video.video" />
                <embed-content
                  ref="embedContent"
                  v-show="false"
                ></embed-content>
              </div>
              <div class="col-5">
                <h6 class="mb-5">Embed Code</h6>
                <v-textarea
                  dense
                  readonly
                  solo
                  auto-grow
                  background-color="grey lighten-4"
                  v-model="content"
                  ref="content"
                  class="embed-text"
                ></v-textarea>
                <v-btn color="success" @click="copyText">
                  Copy To Clipboard
                </v-btn>
              </div>
            </div>
          </div>

          <v-row justify="end">
            <v-btn
              color="primary"
              :loading="getVideosLoading"
              :disabled="getVideosLoading"
              @click="save"
            >
              {{ isCreate ? "Create Video" : "Save changes" }}
            </v-btn>
          </v-row>
        </v-form>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import VideoPlayer from "@/components/VideoPlayer.vue";
import rules from "@/shared/rules";
import store from "@/store";
import { find, get, isEmpty, omit, some } from "lodash";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { mapActions, mapGetters } from "vuex";
import EmbedContent from "./EmbedContent.vue";

export default {
  metaInfo: {
    title: "Video",
  },
  components: { VideoPlayer, vueDropzone: vue2Dropzone, EmbedContent },
  async mounted() {
    this.getLanguages.length ? this.getLanguages : await this.setLanguages();

    if (this.$refs.embedContent) {
      let embedContent = this.$refs.embedContent.$el.outerHTML;
      this.content = embedContent.replace(
        'src=""',
        `src="${get(this.video, "video")}"`
      );
    }
  },
  data() {
    return {
      rule: rules,
      id: _.get(this.$route, "params.id"),
      requiredRule: (v) => !!v || "This is required.",
      hasError: false,
      content: null,
      dropzoneOptions: {
        url: `${process.env.VUE_APP_API_URL}/videos/upload`,
        acceptedFiles: "video/*",
        headers: { Authorization: `Bearer ${store.getters.getToken}` },
        uploadMultiple: false,
        autoProcessQueue: false,
        chunking: true,
        chunkSize: 5242880,
      },
      uploadedFile: null,
    };
  },
  methods: {
    ...mapActions(["setLanguages", "createVideo", "updateVideo"]),

    errorEvent() {
      this.$store.dispatch("showSnackbar", "Error on video upload.");
      this.$store.dispatch("setVideosLoading", false);
    },

    uploadComplete(response) {
      this.$store.dispatch("setVideosLoading", false);

      if (response) {
        this.uploadedFile = JSON.parse(get(response, "xhr.response"));
        this.$refs.myVueDropzone.removeAllFiles();

        this.save();
      }
    },

    async save() {
      const files = this.$refs.myVueDropzone.getQueuedFiles();

      if (files.length) {
        this.$store.dispatch("setVideosLoading", true);
        this.$refs.myVueDropzone.processQueue();
      } else {
        try {
          const payload = {
            ...this.video,
            video: this.uploadedFile,
          };

          if (some(this.video, isEmpty) || isEmpty(this.video)) {
            this.$store.dispatch("showSnackbar", "Fields are required.");

            return;
          }

          this.isCreate
            ? await this.createVideo(payload)
            : await this.updateVideo({ id: this.id, payload });

          this.$store.dispatch(
            "showSnackbar",
            `Video ${this.isCreate ? "created" : "updated"}`
          );

          this.$router.push("/app/video");
        } catch (error) {
          console.error("DEBUG: error", error);
        }
      }
    },

    async copyText() {
      if (window.isSecureContext && navigator.clipboard) {
        await navigator.clipboard.writeText(this.content);

        alert("Copied!");
      }
    },
  },
  computed: {
    ...mapGetters(["getVideos", "getVideosLoading", "getLanguages"]),

    isCreate() {
      return _.get(this.$route, "meta.kind") === "create";
    },

    languages() {
      const selections = [];

      this.getLanguages.forEach((item) => {
        selections.push({
          label: item.name,
          value: item.id,
        });
      });

      return selections;
    },

    video() {
      return {
        ...omit(find(this.getVideos, { id: +this.id }), ["id"]),
      };
    },
  },
};
</script>
<style lang="scss" scoped>
label {
  width: 130px !important;
}

.embed-text {
  font-family: monospace;
}

#videoDropzone {
  padding: 0;
  margin: 10px auto;
  min-height: 0;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0.2);
  border-width: thin;

  .dz-preview {
    height: 150px;
  }

  .dz-details {
    border-radius: 4px;
  }

  .dz-progress {
    margin-top: 25px !important;
  }
}
</style>
